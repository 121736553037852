<template>
    <div class="navbarmobileFix">
        <div>
            <nav class="d-none d-lg-block three-nav">
                <ul class="three-nav navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
                    <li class="three-nav" @click="receiveConsultation()">
                        <div>
                            <img src="~@/assets/images1/doctor-hospital.png" style="width: 40px;">
                        </div>
                    </li>
                    <li class="three-nav" @click="joinPatient()">
                        <div>
                            <img src="~@/assets/images/patientgroup.png" style="width: 40px;">
                        </div>
                    </li>
                    <li class="three-nav" @click="offerConsultation()">
                        <div>
                            <img src="~@/assets/images/meet-icon.png" style="width: 40px;">
                        </div>
                    </li>
                    <li class="three-nav" @click="accessClinical()">
                        <div>
                            <img src="~@/assets/img/medical-knowledge.png" style="width: 40px;">
                        </div>
                    </li>
                    <!-- <li class="three-nav" @click="postConsultation()">
                    <div>
                        <img src="~@/assets/images/group.png" style="width: 40px;">
                    </div>
                </li> -->
                </ul>
            </nav>


            <section class="banner-text doctorcommunity">
                <div class="container text-center text-align">
                    <!-- FOR DESKTOP  -->
                    <div class="doctor-communityTextHeading d-none d-lg-block">
                        <h1 class="">Join doctor communities</h1>
                    </div>
                    <!-- FOR MOBILE  -->
                    <div class="doctor-communityTextHeading d-block d-lg-none">
                        <h1 class="mb-0">Join</h1>
                        <h1 class="mb-0">doctor communities</h1>
                    </div>
                    <div class="row">
                        <!-- FOR DESKTOP  -->
                        <div class="col-12 d-none d-lg-block banner-sub-content doctor-communityText">
                            <h3 class="mb-0">Get case inputs from fellow doctors and offer improved</h3>
                            <h3>consultation.</h3>

                            <h3 class="mt-4 mb-0">Share your knowledge and enhance your standing among</h3>
                            <h3> your peers.</h3>

                        </div>
                        <!-- FOR MOBILE  -->
                        <div class="col-12 d-block d-lg-none banner-sub-content doctor-communityText">
                            <h3 class="mb-0">Get case inputs from fellow</h3>
                            <h3 class="mb-0">doctors and offer improved</h3>
                            <h3>consultation.</h3>


                            <h3 class="mt-4 mb-0">Share your knowledge and</h3>
                            <h3 class="mb-0">enhance your standing among</h3>
                            <h3>your peers.</h3>

                        </div>
                    </div>
                </div>
            </section>

            <section class="banner-text mt-3">
                <div class="container text-center text-align">
                    <!-- FOR DESKTOP  -->
                    <div class="headingGetCase d-none d-lg-block ">
                        <h2 class="mb-0">Select a community </h2>
                        <h2>aligned with your practice</h2>
                    </div>
                    <!-- FOR MOBILE  -->
                    <div class="headingGetCase d-block d-lg-none">
                        <h2 class="mb-0">Select a community</h2>
                        <h2 class="mb-0">aligned with your</h2>
                        <h2>practice</h2>
                    </div>

                    <div class="row box-items">
                        <div class="col-opt" v-for="index in options" :key="index">
                            <div class="col-fix mt-3" @click="signupdoctor( index)">
                                <div class="btn-text">
                                    {{ index.practiceArea }}
                                    <div>Doctors</div>
                                </div>
                                
                                <div>
                                    <button class="btn btn-action">Join
                                        community</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <MobileNavbar></MobileNavbar>
</template>
<script>
import { defineComponent } from "vue";
import MobileNavbar from "../common-mobile-footer.vue"
import axios from "axios"
export default defineComponent({
    components: {
        MobileNavbar
    },
    el: '#hide',
    data() {
        return {
            seen: true,
            options: [],
            getcaseInput: true,
            currentPath: "",
            practiceArea: [],
            hcpDoctorData: {}
        };
    },
    created: function () {
        this.getCase();
        this.currentPath = this.$route.path.split('/')[2];
    },
    methods: {
        accessClinical() {
            window.location.href = "/doctor/clinicalresources"; 
        },
        postConsultation() {
      window.location.href = "/presign/postconsultation"; 
        },
        receiveConsultation() {
      window.location.href = "/doctor/consultationrequests"; 
        },
        offerConsultation() {
      window.location.href = "/doctor/offerconsultation"; 

        },
        joinPatient() {
      window.location.href = "/doctor/patientgroups"; 
        },
        signupdoctor( queryParams) {
            const getCase ={
                practicearea: JSON.stringify(queryParams),
                routeName : 'getcase',
                card:'doctor',
                practiceAreaId:3
            }

            this.$router.push({ path: '/signup', query: getCase });
            // localStorage.removeItem('addGroup');
            // this.issubmit = true
        },
        getCase() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/hcp-types?hcpTypes=doctor`)
                .then((response) => {
                    if (response?.data) {
                        response.data.map((data) => {
                            if (data.is_delete === false && data.check === true) {
                                this.options.push(data)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
    },
    name: "HomePageB",
    props: {},
});

</script>
<style>
.doctor-communityTextHeading h1 {
    font-weight: bold;
    text-align: center;
    color: #000000;
}

.doctor-communityTextHeading {
    margin-top: 20px;
}

.headingGetCase h2 {
    color: #000;
    text-align: center;

}

.headingGetCase {
    margin-top: 3rem;
}

.navigation-list-item-blk {
    transition: 0.3s;
    /* transform: translateX(-100px); */
    transform: translateX(75px);
}

.show-navigation-list {
    transform: translateX(0px);
}

button.presign.btn.btn-blue-color.text-white {
    float: right;
    width: 40%;
    /* width: auto; */
    font-weight: bold;
    font-size: large;
    /* margin: 10px -60px 10px 0px; */
}

.rxix-menu li.nav-item a.nav-link {
    color: #00979e;
}

nav.navbar.navbar-expand-lg.navbar-light {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
}



section.banner-text.doctorcommunity {
    margin-top: 4.5rem;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 593px;
    /* transform: rotate(90deg);  */
    padding: 30px 0px 50px 0px;
}


.banner-sub-content {
    margin-top: 20px;
}

button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
    padding: 0.375rem 0.75rem;
}



.row.box-items {
    margin-top: 40px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 22% 22% 22%;
    justify-content: center;
    cursor: pointer;
}


.col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
}

.col-opt {
    width: 100%;
    display: grid;
    justify-content: center;
}

.col-fix.mt-3 {
    border: 2px solid #00979e;
    width: 206px;
    /* margin-bottom: 30px; */
}


@media screen and (max-width:991px) {
    .col-box {
        display: flex;
        margin: auto;
    }
}

.btn-text {
    text-align: center;
    padding: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #00979e;
}

@media screen and (max-width:991px) {
    .btn-text {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 20px;
        color: #00979e;
    }
}


button.btn.btn-blue-color.text-white {
    width: 100%;
    font-size: large;
    font-weight: bold;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

:after {
    content: "";
}

/* nav.three-nav {
    float: right;
    position: relative;
    top: 52px;
    left: 70px;
    background: transparent;
} */
nav.three-nav {
    float: right;
    position: fixed;
    right: 0;
    background: transparent;
}

nav.three-nav:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav ul.three-nav {
    text-align: center;
    /* margin-left: -72px; */
    margin-top: 28px;
    /* margin-top: 77px; */
}

@media screen and (max-width:992px) {
    nav.three-nav ul.three-nav {
        text-align: center;
        /* margin-left: -72px; */
        margin-top: 0px
            /* margin-top: 77px; */
    }
}

nav.three-nav ul.three-nav li.three-nav {
    position: relative;
    width: 71px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav ul.three-nav li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    /* right: 70px; */
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav ul.three-nav li:nth-child(1):after {
    /* content: "dcba"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(2):after {
    /* content: "join"; */
    line-height: 88px;
}

nav.three-nav ul.three-nav li:nth-child(3):after {
    /* content: "offer"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:nth-child(4):after {
    /* content: "access"; */
    line-height: 70px;
}

nav.three-nav ul.three-nav li:nth-child(5):after {
    /* content: "post"; */
    line-height: 85px;
}

nav.three-nav ul.three-nav li:hover {
    /* transform: translateX(0px); */
    /* transform: translateX(70px); */
    /* transform: rotateY(-360deg); */
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav ul.three-nav li:hover:after {
    opacity: 1;
    /* transform: perspective(400px) rotateY(0deg) scale(1); */
    /* transform: perspective(400px) rotateY(180deg) scale(1); */
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav ul.three-nav li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav ul.three-nav li.three-nav div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu {
    list-style: none;
    position: relative;
    left: 35px;
    /* right: 35px; */
    /* top: 200px; */
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0 solid !important;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

@media screen and (max-width:992px) {
    .row.box-items {
        grid-gap: 70px;
    }

    .col-fix.mt-3 {
        width: 200px;
    }

    .doctor-communityTextHeading h1 {
        font-size: 32px;
    }

    .doctor-communityText h3 {
        font-size: 22px;
    }

    .headingGetCase h2 {
        font-size: 28px;
    }
}

@media screen and (max-width:991px) {
    .navbarmobileFix {
        margin-bottom: 7rem;
    }

    section.banner-text.doctorcommunity {
        margin-top: 0px;
        padding: 30px 0px 35px 0px
    }

    .headingGetCase {
        margin-top: 2rem;
    }
}

@media screen and (max-width:768px) {
    .row.box-items {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}

@media screen and (max-width:576px) {
    .doctor-communityTextHeading h1 {
        font-size: 25px;
    }

    .doctor-communityText h3 {
        font-size: 17px;
    }

    .headingGetCase h2 {
        font-size: 21px;
    }

    section.banner-text.doctorcommunity {
        padding: 10px 0px 17px 0px;
    }

    .headingGetCase {
        margin-top: 1.5rem;
    }
}


@media screen and (max-width:550px) {
    .row.box-items {
        margin-top: 10px;
    }

}

@media screen and (max-width:470px) {
    .row.box-items {
        grid-gap: 0.1px;
    }

    .col-fix.mt-3 {
        width: 130px;
    }

    .btn-text {
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }

    button.btn.btn-action {
        padding: 0.4rem 0.2rem;
    }
}</style>